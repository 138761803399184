export function ConcurrencyParallelDuoIcon({
  className,
  color = "currentColor",
}: {
  className?: string;
  color?: string;
}) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={
        {
          "--icon-color": color,
          "--icon-color-dark": `color-mix(in srgb, var(--icon-color) 35%, black)`,
        } as React.CSSProperties
      }
    >
      <g clipPath="url(#clip0_13914_95101)">
        <rect
          x="16.75"
          y="23.25"
          width="22.5"
          height="6.5"
          rx="3.25"
          transform="rotate(-90 16.75 23.25)"
          fill="var(--icon-color)"
          stroke="var(--icon-color)"
          strokeWidth="1.5"
        />
        <rect
          x="1"
          y="15"
          width="6"
          height="6"
          rx="3"
          transform="rotate(-90 1 15)"
          fill="var(--icon-color)"
        />
        <rect
          x="7.99997"
          y="15"
          width="6"
          height="6"
          rx="3"
          transform="rotate(-90 7.99997 15)"
          fill="var(--icon-color)"
        />
        <rect
          x="1"
          y="23"
          width="6"
          height="6"
          rx="3"
          transform="rotate(-90 1 23)"
          fill="var(--icon-color)"
        />
        <rect
          x="7.99997"
          y="23"
          width="6"
          height="6"
          rx="3"
          transform="rotate(-90 7.99997 23)"
          fill="var(--icon-color)"
        />
        <rect
          x="1"
          y="7"
          width="6"
          height="6"
          rx="3"
          transform="rotate(-90 1 7)"
          fill="var(--icon-color)"
        />
        <rect
          x="7.99997"
          y="7"
          width="6"
          height="6"
          rx="3"
          transform="rotate(-90 7.99997 7)"
          fill="var(--icon-color)"
        />
        <rect
          x="16.75"
          y="15.25"
          width="6.5"
          height="6.5"
          rx="3.25"
          transform="rotate(-90 16.75 15.25)"
          fill="var(--icon-color-dark)"
          stroke="var(--icon-color)"
          strokeWidth="1.5"
        />
        <rect
          x="16.75"
          y="23.25"
          width="6.5"
          height="6.5"
          rx="3.25"
          transform="rotate(-90 16.75 23.25)"
          fill="var(--icon-color-dark)"
          stroke="var(--icon-color)"
          strokeWidth="1.5"
        />
        <rect
          x="16.75"
          y="7.25"
          width="6.5"
          height="6.5"
          rx="3.25"
          transform="rotate(-90 16.75 7.25)"
          fill="var(--icon-color-dark)"
          stroke="var(--icon-color)"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_13914_95101">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
